import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ReactPlayer from "react-player"
import { Row, Col, Accordion, Card } from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.css"

import("../pages/index.scss")

function Welcome(props) {
  return (
    <p className="fade-fast">
      Carol Burri <span className="grey fade-slow">{props.intro}</span>
    </p>
  )
}

class CustomToggle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      play: false,
    }
  }

  render() {
    const { play } = this.state
    return (
      <Card className="col-12" key={this.props.id}>
        <Accordion.Toggle
          as={Card.Header}
          variant="link"
          eventKey={this.props.eventKey}
          className="row justify-content-end fade-in-slow curser borderbottom"
          onClick={event => {
            this.setState({ play: !this.state.play })
          }}
        >
          <Col className="col-lg-8 col-md-6 col-12 fade-in-slow">
            <h2 dangerouslySetInnerHTML={{ __html: `${this.props.title}` }} />
          </Col>
          <Col className="col-lg-2 col-md-3 d-none d-sm-block fade-in-slow">
            {this.props.text ? (
              <span dangerouslySetInnerHTML={{ __html: `Tech: ${this.props.text}` }} />
            ) : null }
          </Col>
          <Col className="col-lg-2 col-md-3 d-none d-sm-block fade-in-slow">
            {this.props.type ? (
              <div
                className="type"
                dangerouslySetInnerHTML={{ __html: `Type: ${this.props.type}` }}
              />
            ) : (
              ``
            )}
          </Col>
        </Accordion.Toggle>
        <CustomCollpase
          playing={play}
          eventKey={this.props.eventKey}
          video={this.props.video}
          url={this.props.url}
          collaboration={this.props.collaboration}
        ></CustomCollpase>
      </Card>
    )
  }
}

class CustomCollpase extends React.Component {
  render() {
    return (
      <Accordion.Collapse eventKey={this.props.eventKey}>
        <Card.Body>
          <Row className="align-items-center">
            <Col className="col-sm-5 col-12">
              <ReactPlayer
                url={this.props.video}
                playing={this.props.playing}
                controls={false}
                loop={true}
                playsinline
                width="100%"
                height="100%"
                wrapper="iframe-container"
              />
            </Col>
            <Col className="col-sm-3 col-12 mt-4 mt-sm-0">
              {this.props.url ? (
                <div className="url">
                  <a
                    href={this.props.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {this.props.url.slice(7)}
                  </a>
                </div>
              ) : (
                ``
              )}
              {this.props.collaboration ? (
                <div
                  className="collaboration"
                  dangerouslySetInnerHTML={{
                    __html: `Collaboration: ${this.props.collaboration}`,
                  }}
                />
              ) : (
                ``
              )}
            </Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    )
  }
}

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Row>
      <Col className="col-12 col-lg-8 col-md-10 intro mb-4 pb-4 fade-in-fast">
        <div className="fromTop">
          {data.allWordpressAcfOptions.edges.map(
            (options, id) => (
              console.log(id),
              (<Welcome key={id} intro={options.node.options.intro} />)
            )
          )}
        </div>
      </Col>
    </Row>
    <Accordion className="row mt-4">
      {data.allWordpressWpProjects.edges.map((post, index) => (
        <CustomToggle
          key={index}
          title={post.node.title}
          type={post.node.acf.type}
          text={post.node.acf.text}
          loop={true}
          eventKey={post.node.id}
          video={post.node.acf.video_src.localFile.url}
          url={post.node.acf.url}
          collaboration={post.node.acf.collaboration}
        ></CustomToggle>
      ))}
    </Accordion>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allWordpressWpProjects(sort: { fields: menu_order, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          acf {
            text
            url
            collaboration
            type
            video_src {
              localFile {
                id
                url
              }
            }
            feat_img {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 750) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            intro
          }
        }
      }
    }
  }
`
